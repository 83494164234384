var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"base py-3 mb-3",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex pl-4 ml-sm-3 mr-sm-n3 justify-center align-center",attrs:{"cols":"3","sm":"1"}},[_c('v-img',{staticClass:"shrink",attrs:{"alt":"Vuetify Logo","contain":"","src":_vm.quote.fleet.logoUrl,"transition":"scale-transition","width":_vm.$vuetify.breakpoint.mobile ? '65%' : '90%'}})],1),_c('v-col',{staticClass:"d-flex flex-column pl-0 pl-sm-4 justify-center",attrs:{"cols":"7","sm":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('PAGE2.VEHICULE_CLASS.' + _vm.quote.vehicle.class.toUpperCase()))+" ")]),_c('div',{staticClass:"px-9"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#f1c947","icon":""}},'v-btn',attrs,false),on),[_c('Stars',{attrs:{"rating":_vm.calculateRating()}})],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('QUOTE.REVIEWS', { rating: _vm.humanizeRating(_vm.quote.fleet.rating), countReviews: _vm.quote.fleet.countReviews > 10 ? ("(" + (_vm.quote.fleet.countReviews) + ")") : '', interpolation: { escapeValue: false }, })))]),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.$t('QUOTE.RELIABILITY', { reliability: _vm.humanizeRating(_vm.quote.fleet.reliability), interpolation: { escapeValue: false } })))])])],1),_c('div',[_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"cancellation-policy-link"},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('SHARED.TERMS', { fleetName: _vm.quote.fleet.name }))+" ")])]}}]),model:{value:(_vm.termsDialog),callback:function ($$v) {_vm.termsDialog=$$v},expression:"termsDialog"}},[_c('v-card',{staticStyle:{"height":"80vh"}},[_c('v-card-title',{staticStyle:{"border-bottom":"1px solid lightgrey"}},[_vm._v(" "+_vm._s(_vm.$t('SHARED.TERMS_AND_CONDITIONS', { fleetName: _vm.quote.fleet.name }))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"var(--color-blue-darken-1)"},on:{"click":function($event){_vm.termsDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-0",staticStyle:{"height":"calc(100% - 64px)"}},[_c('embed',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.quote.fleet.termsAndConditionsUrl}})])],1)],1)],1),(_vm.quote.serviceLevelAgreement.freeCancellationType !== 'none')?_c('v-card-text',{staticClass:"m-0 p-0 card-text-span"},[(this.quote.serviceLevelAgreement.freeCancellationTime > 0)?_c('span',{staticClass:"mb-0 pr-1 pb-1"},[_vm._v(" "+_vm._s(_vm.$t('QUOTE.FREE_CANCELLATION', { time: _vm.durationHuman(_vm.quote.serviceLevelAgreement.freeCancellationTime) }))+" ")]):_vm._e(),(this.quote.serviceLevelAgreement.freeWaitingTime > 0)?_c('span',{staticClass:"mb-0 pr-1 pb-1"},[_vm._v(" "+_vm._s(_vm.$t('QUOTE.FREE_WAITING_TIME', { time: _vm.durationHuman(_vm.quote.serviceLevelAgreement.freeWaitingTime) }))+" ")]):_vm._e()]):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"3","sm":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"var(--color-blue-darken-1)","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.quote.fleet.description))])])],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"4","sm":"1"}},[(_vm.quote.vehicle.eta)?_c('span',[_vm._v(" "+_vm._s(_vm.durationHuman(~~((_vm.quote.vehicle.eta + 31) / 60) * 60) /* Rounds ETA to a minute up */)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-sm-column justify-center align-center",attrs:{"cols":"5","sm":"1"}},[_c('div',{staticClass:"mr-5 mr-sm-0",staticStyle:{"min-width":"50px"}},[_c('v-icon',[_vm._v("mdi-account-multiple")]),_vm._v("  "+_vm._s(_vm.quote.vehicle.passengerCapacity))],1),_c('div',{staticStyle:{"min-width":"50px"}},[_c('v-icon',[_vm._v("mdi-bag-suitcase-outline")]),_vm._v("  "+_vm._s(_vm.quote.vehicle.luggageCapacity))],1),(_vm.quote.vehicle.passengerCapacity < _vm.passengersCount || _vm.quote.vehicle.luggageCapacity < _vm.luggagesCount)?_c('div',[_vm._v("⚠️")]):_c('div',[_vm._v("✅")])]),_c('v-col',{staticClass:"d-flex justify-sm-end justify-center align-center",attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{staticClass:"px-2 mx-2",attrs:{"color":"var(--color-orange-darken-1)","depressed":"","dark":"","width":"100%","max-width":"120"},on:{"click":function($event){return _vm.onSelect()}}},[_c('div',{staticClass:"d-flex flex-column align-center m-0 p-0"},[_c('p',{staticClass:"m-0 p-0 btn-text"},[_vm._v(_vm._s(_vm.quote.price.type === 'FIXED' ? '' : '~')+_vm._s(_vm.price(_vm.quote.price)))]),_c('p',{staticClass:"m-0 p-0 btn-text btn-text__subline"},[_vm._v(" "+_vm._s(_vm.quote.price.type === 'FIXED' ? _vm.$t('SHARED.PRICE.TYPE.FIXED') : _vm.$t('SHARED.PRICE.TYPE.METERED'))+" ")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
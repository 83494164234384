
























































































































































import Vue from 'vue';
import Stars from '@/components/Stars.vue';
import format from '@/services/format';

export default Vue.extend({
    name: 'QuoteItem',

    props: ['quote', 'onPress', 'passengersCount', 'luggagesCount'],

    components: { Stars },

    data() {
        return {
            termsDialog: false,
        };
    },

    mixins: [{ methods: { ...format } }],

    methods: {
        calculateRating() {
            return (this.quote.fleet.rating + this.quote.fleet.reliability) / 2;
        },

        humanizeRating(rate: number) {
            return `${(rate / 20).toFixed(1)}/5`;
        },

        onSelect() {
            this.onPress(this.quote);
        },
    },
});














import Vue from 'vue';

export default Vue.extend({
    name: 'Stars',

    props: [
        'rating', // expects a value between 0 and 100`
        'reliability',
    ],

    data() {
        return {
            rating10: Math.round(this.rating / 10),
        };
    },

    methods: {
        drawStar(position: number) {
            const index = position * 2 + 1;
            const rating = this.rating10;
            if (rating >= index + 1) {
                return 'mdi-star';
            }
            if (rating >= index) {
                return 'mdi-star-half-full';
            }
            return 'mdi-star-outline';
        },
    },
});














































































































































































































import Vue from 'vue';
import dayjs, { Dayjs } from 'dayjs';
import AddressAutoComplete from '@/components/AddressAutoComplete.vue';
import DateTime from '@/components/DateTime.vue';
import Map from '@/components/Map.vue';
import QuoteItem from '@/components/QuoteItem.vue';
import ValidityTimer from '@/components/ValidityTimer.vue';
import log from '@/services/log';
import mytako, { CreateQuoteRequestRequest, Location, Quote } from '@/services/mytako';
import segment from '@/services/segment';
import store from '@/services/store';

export default Vue.extend({
    metaInfo: {
        title: 'Quotes',
    },

    components: { QuoteItem, ValidityTimer, DateTime, Map, AddressAutoComplete },

    data() {
        return {
            pickupLocation: null as Location | null,
            destinationLocation: null as Location | null,
            asap: 'true',
            pickupAt: undefined as Dayjs | undefined,
            passengersCount: 1,
            passengersSelect: [1, 2, 3, 4, 5, 6, 7, 8],
            luggagesCount: 0,
            luggagesSelect: [0, 1, 2, 3, 4, 5, 6, 7, 8],

            quoteStatus: '',
            vehicleType: 'any',
            quotes: [],
            quotesToDisplay: [],
            quotesExpirationTimeoutId: -1,
            quotesLoaderTimeoutId: -1,

            quotesSortByPrice: 1, // 1: Up, -1: Down
            quotesSortByEta: 1, // 1: Up, -1: Down
            quotesFilterByPriceType: [] as number[],
            quotesFilters: {
                byPriceType: {
                    FIXED: true,
                    METERED: true,
                },
                byVehiculeCategory: [] as string[],
            },
            sortQuotes: () => {
                // nop
            },
            areQuotesLoading: false,
            showDialogExpired: false,
            deadline: undefined as number | undefined,
        };
    },

    watch: {
        quotesFilterByPriceType: function (value) {
            this.onChangeFilterQuotesByPriceType('FIXED', value.includes(0));
            this.onChangeFilterQuotesByPriceType('METERED', value.includes(1));
        },
    },

    mounted() {
        log.warn('***** PAGE-2');
        this.$vuetify.goTo(0);
        segment.page(`result`);

        const { pickupLocation, destinationLocation, passengerCount, luggageCount, pickupAt } = store.quoteRequest.get() as CreateQuoteRequestRequest;
        this.pickupLocation = pickupLocation;
        this.destinationLocation = destinationLocation;
        this.passengersCount = passengerCount ?? 1;
        this.luggagesCount = luggageCount ?? 0;
        if (pickupAt) {
            this.pickupAt = dayjs(pickupAt);
            this.asap = 'false';
        }

        if (this.quotesFilters.byPriceType.FIXED) {
            this.quotesFilterByPriceType.push(0);
        }

        if (this.quotesFilters.byPriceType.METERED) {
            this.quotesFilterByPriceType.push(1);
        }
        this.sortQuotes = this.sortQuotesByPrice;

        store.booking.clear();
        store.quote.clear();
        store.quoteValidityDeadline.clear();
        this.createNewQuoteRequest();
    },

    methods: {
        notCheckedClassButton(buttonId: number) {
            return this.quotesFilterByPriceType.includes(buttonId) ? '' : 'not-checked';
        },

        onChangePickupLocation(location: Location) {
            log.debug('onChangePickupLocation');
            this.pickupLocation = location;
            this.onChangeQuoteSearch();
        },

        onChangeDestinationLocation(location: Location) {
            log.debug('onChangeDestinationLocation');
            this.destinationLocation = location;
            this.onChangeQuoteSearch();
        },

        onChangeDateTime(dateTime: Dayjs) {
            log.debug('[Request] onChangeDateTime >', dateTime?.toString());
            this.pickupAt = dateTime;
            this.onChangeQuoteSearch();
        },

        clearQuotesExpirationTimer() {
            log.debug('clear quotesExpirationTimeoutId');
            clearTimeout(this.quotesExpirationTimeoutId);
        },

        clearQuotesLoaderTimer() {
            log.debug('clear quotesLoaderTimeoutId');
            clearTimeout(this.quotesLoaderTimeoutId);
        },

        clearTimers() {
            this.clearQuotesExpirationTimer();
            this.clearQuotesLoaderTimer();
        },

        updateQuotesToDisplay() {
            this.quotesToDisplay = this.quotes.slice();

            // Apply filter by vehicule category
            if (this.quotesFilters.byVehiculeCategory.length > 0) {
                this.quotesToDisplay = this.quotesToDisplay
                    .slice()
                    .filter((v: { vehicle: { class: string } }) => this.quotesFilters.byVehiculeCategory.includes(v.vehicle.class));
            }

            // Apply filter by FIXED
            if (!this.quotesFilters.byPriceType.FIXED) {
                this.quotesToDisplay = this.quotesToDisplay.slice().filter((p: { price: { type: string } }) => {
                    return p.price.type !== 'FIXED';
                });
            }

            // Apply filter by METERED
            if (!this.quotesFilters.byPriceType.METERED) {
                this.quotesToDisplay = this.quotesToDisplay.slice().filter((p: { price: { type: string } }) => {
                    return p.price.type !== 'METERED';
                });
            }

            this.sortQuotes();
        },

        async createNewQuoteRequest() {
            log.debug('createNewQuoteRequest');
            this.areQuotesLoading = true;
            store.quoteValidityDeadline.clear();
            this.clearQuotesExpirationTimer();
            if (this.pickupLocation == null || this.destinationLocation == null) {
                return null;
            }
            const quoteRequest: CreateQuoteRequestRequest = {
                pickupLocation: this.pickupLocation,
                destinationLocation: this.destinationLocation,
                passengerCount: this.passengersCount,
                luggageCount: this.luggagesCount,
                pickupAt: this.asap === 'true' ? undefined : this.pickupAt?.format('YYYY-MM-DDTHH:mm'),
                // TODO: add address autocomplete connector / information here
            };
            segment.track('result/search', {
                pickupLocation: this.pickupLocation,
                destinationLocation: this.destinationLocation,
                passengerCount: this.passengersCount,
                luggageCount: this.luggagesCount,
                pickupAt: this.asap === 'true' ? undefined : this.pickupAt?.format('YYYY-MM-DDTHH:mm'),
            });
            log.debug('quoteRequest', quoteRequest);
            store.quoteRequest.set(quoteRequest);
            try {
                const quoteRequestResponse = await mytako.api.requests.createQuoteRequest(quoteRequest);
                // save quote request
                store.quoteRequestResponse.set(quoteRequestResponse);
                // handle validity
                const validityDuration = quoteRequestResponse.validity;
                if (validityDuration > 0) {
                    const quoteValidityDeadline = dayjs().unix() + validityDuration;
                    this.deadline = quoteValidityDeadline;
                    store.quoteValidityDeadline.set(quoteValidityDeadline);
                    log.debug(`Quotes will be valid for ${quoteRequestResponse.validity} seconds until ${dayjs.unix(quoteValidityDeadline)}`);
                    // segment.track('result/time-before-expiration', { timer: `${dayjs.unix(this.deadline).diff(dayjs()) / 1000}s` });
                    this.quotesExpirationTimeoutId = setTimeout(() => {
                        this.showDialogExpired = true;
                        log.debug('Quotes have expired!');
                        segment.track('result/quotes/expired');
                    }, validityDuration * 1000);
                }
                await this.loadQuotes(quoteRequestResponse.uuid);
            } catch {
                // TODO if 404/NotFound => show info to user
                this.areQuotesLoading = false;
            }
        },

        async loadQuotes(requestUuid: string) {
            log.debug('loadQuotes');
            this.areQuotesLoading = true;
            this.clearQuotesLoaderTimer();
            try {
                const data = await mytako.api.requests.getQuotes({ requestUuid });
                this.quotes = (data?.quotes as []) ?? [];
                this.updateQuotesToDisplay();

                // more to do?
                switch (data.status) {
                    case 'PROGRESSING':
                        this.quotesLoaderTimeoutId = setTimeout(async () => {
                            await this.loadQuotes(requestUuid);
                        }, 1500);
                        break;

                    case 'COMPLETED':
                        this.areQuotesLoading = false;
                        if (this.quotes.length === 0) {
                            store.quoteValidityDeadline.clear();
                            this.clearQuotesExpirationTimer();
                            this.deadline = undefined;
                        }
                        break;
                }
            } catch (error: any) {
                log.error(error);
            }
        },

        onChangeQuoteSearch() {
            log.debug('onChangeQuoteSearch');
            this.vehicleType = 'any';
            this.showDialogExpired = false;
            return this.createNewQuoteRequest();
        },

        onQuoteSelect(quote: Quote) {
            log.debug('onQuoteSelect');
            segment.track('result/quote/select', {
                price: { ...quote.price, amount: quote.price.amount / 100 },
                fleetName: quote.fleet.name,
                fleetRating: quote.fleet.rating,
                timerStatus: this.deadline ? dayjs.unix(this.deadline).diff(dayjs()) / 1000 : null,
            });
            this.clearTimers();
            store.quote.set(quote);
            this.$router.push({ name: 'Booking' });
        },

        onChangeVehicleType(ev: { target: { value: string } }) {
            segment.track('result/quotes/filter/by-vehicule-type', { vehicle: { class: ev.target.value } });
            this.quotesFilters.byVehiculeCategory = ev.target.value === 'any' ? [] : [ev.target.value]; // todo be more genreric
            this.updateQuotesToDisplay();
        },

        onChangeFilterQuotesByPriceType(priceType: string, on: boolean) {
            segment.track(`result/quotes/filter/by-price-type`, { priceType: priceType });
            (this.quotesFilters.byPriceType as any)[priceType] = on;
            this.updateQuotesToDisplay();
        },

        onClickSortQuotesByPrice() {
            log.debug('onClickSortQuotesByPrice');
            this.quotesSortByPrice *= -1;
            this.sortQuotes = this.sortQuotesByPrice;
            segment.track('result/quotes/sort/by-price', { sort: this.quotesSortByPrice === 1 ? 'ascending' : 'descending' });
            this.sortQuotesByPrice();
        },

        sortQuotesByPrice() {
            interface PriceAmount {
                price: {
                    amount: number;
                };
            }
            log.debug('quotes are now sorted by price');
            this.quotesToDisplay.sort((a: PriceAmount, b: PriceAmount) => {
                return this.quotesSortByPrice * (a.price.amount - b.price.amount);
            });
        },

        onClickSortQuotesByETA() {
            log.debug('onClickSortQuotesByETA');
            this.quotesSortByEta *= -1;
            this.sortQuotes = this.sortQuotesByETA;
            segment.track('result/quotes/sort/by-eta', { sort: this.quotesSortByEta === 1 ? 'descending' : 'ascending' });
            this.sortQuotesByETA();
        },

        sortQuotesByETA() {
            interface VehicleETA {
                vehicle: { eta: number };
            }
            this.quotesToDisplay.sort((a: VehicleETA, b: VehicleETA) => {
                return this.quotesSortByEta * (a.vehicle.eta - b.vehicle.eta);
            });
        },
    },
});
